<template>
  <div>
    <!-- === COLLAPSE MAIN MENU === -->
    <div
      v-if="data.main_menu"
      :class="collapse.mainMenu ? '' : 'border-bottom border-gray'"
    >
      <div
        @click="collapse.mainMenu = !collapse.mainMenu"
        class="media py-2"
        :aria-expanded="collapse.mainMenu ? 'true' : 'false'"
        aria-controls="collapse-tags"
      >
        <div class="mr-1 text-white bg-primary rounded" style="padding: 8px">
          <feather-icon size="20" icon="ListIcon" />
        </div>

        <div
          class="d-flex justify-content-between w-100"
          style="padding-top: 10px"
        >
          <h5>
            {{ $t("main_menu") }}
          </h5>

          <div>
            <feather-icon
              size="20"
              :icon="collapse.mainMenu ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            />
          </div>
        </div>
      </div>

      <b-collapse id="collapse-main-menu" v-model="collapse.mainMenu">
        <b-list-group>
          <b-list-group-item>
            <div class="px-2 row">
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("labels.backgroundcolor") }}</h5>
                <b-form-input
                  type="color"
                  @change="onColorChange($event, 'main_menu.background_color')"
                  :value="data.main_menu.background_color"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("primary_text_color") }}</h5>
                <b-form-input
                  type="color"
                  v-model="data.main_menu.primary_text_color"
                  @input="updateParent"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("secondary_text_color") }}</h5>
                <b-form-input
                  type="color"
                  v-model="data.main_menu.secondary_text_color"
                  @input="updateParent"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </div>

    <!-- === COLLAPSE FEATURED CARDS === -->
    <div
      v-if="data.featured_card"
      :class="collapse.featuredCard ? '' : 'border-bottom border-gray'"
    >
      <div
        @click="collapse.featuredCard = !collapse.featuredCard"
        class="media py-2"
        :aria-expanded="collapse.featuredCard ? 'true' : 'false'"
        aria-controls="collapse-tags"
      >
        <div class="mr-1 text-white bg-primary rounded" style="padding: 8px">
          <feather-icon size="20" icon="ListIcon" />
        </div>

        <div
          class="d-flex justify-content-between w-100"
          style="margin-top: 10px"
        >
          <h5>
            {{ $t("featured_cards_section") }}
          </h5>

          <div>
            <feather-icon
              size="20"
              :icon="collapse.brands ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            />
          </div>
        </div>
      </div>

      <b-collapse id="collapse-featured-card" v-model="collapse.featuredCard">
        <b-list-group>
          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("labels.background-color") }}</h5>
                <b-form-input
                  type="color"
                  @change="
                    onColorChange($event, 'featured_card.background_color')
                  "
                  :value="data.featured_card.background_color"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("primary_text_color") }}</h5>
                <b-form-input
                  type="color"
                  v-model="data.featured_card.primary_text_color"
                  @input="updateParent"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("secondary_text_color") }}</h5>
                <b-form-input
                  type="color"
                  v-model="data.featured_card.secondary_text_color"
                  @input="updateParent"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <b-form-group
                class="w-100 col-lg-6"
                label-for="name"
                :state="Boolean(data.name)"
              >
                <h5>{{ $t("labels.name") }}</h5>
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    aria-describedby="input-live-help input-live-feedback"
                    v-model="data.featured_card.title"
                    @input="updateParent"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 d-flex flex-column flex-wrap">

              <div class="w-100" v-if="data.featured_card.img">
                <b-button class="close-btn" @click="onClearImage('featured_card.img')" variant="outline-primary"
                          size="sm">
                  X
                </b-button>
              </div>
              <!-- Field: lobby Name -->
              <img
                alt=""
                style="
                  object-fit: contain;
                  height: 200px;
                  width: 100%;
                  margin: 10px;
                "
                :src="data.featured_card.img"
              />

              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("labels.image") }}</h5>
                <image-field-form
                    :single-button="true"
                  :text="$t('labels.image')"
                  class="w-100"
                  @previewImage="setPreviewImage()"
                  v-model="data.featured_card.img"
                  @changeImage="onChangeImage($event, 'featured_card.img')"
                  @uploadedImage="onUploadedImage($event, 'featured_card.img')"
                  @error="errorInLoadImage"
                  path="lobby"
                  :images="images"
                >
                </image-field-form>
              </b-form-group>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </div>

    <!-- === COLLAPSE TAGS === -->
    <div
        v-if="data.tags_menu"
        :class="collapse.tags ? '' : 'border-bottom border-gray'"
    >
      <div
          @click="collapse.tags = !collapse.tags"
          class="media py-2"
          :aria-expanded="collapse.tags ? 'true' : 'false'"
          aria-controls="collapse-tags"
      >
        <div class="mr-1 text-white bg-primary rounded" style="padding: 8px">
          <feather-icon size="20" icon="ListIcon" />
        </div>

        <div
            class="d-flex justify-content-between w-100"
            style="margin-top: 10px"
        >
          <h5>
            {{ $t("tags_menu") }}
          </h5>

          <div>
            <feather-icon
                size="20"
                :icon="collapse.tags ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            />
          </div>
        </div>
      </div>

      <b-collapse id="collapse-tags" v-model="collapse.tags">
        <b-list-group>
          <b-list-group-item>
            <div class="px-2 row">
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("labels.backgroundcolor") }}</h5>
                <b-form-input
                    type="color"
                    @change="onColorChange($event, 'tags_menu.background_color')"
                    :value="data.tags_menu.background_color"
                    trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("primary_text_color") }}</h5>
                <b-form-input
                    type="color"
                    v-model="data.tags_menu.primary_text_color"
                    @input="updateParent"
                    trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("secondary_text_color") }}</h5>
                <b-form-input
                    type="color"
                    v-model="data.tags_menu.secondary_text_color"
                    @input="updateParent"
                    trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </div>
    
    <!-- === COLLAPSE BRANDS === -->
    <div
        v-if="data.brands_menu"
        :class="collapse.brands ? '' : 'border-bottom border-gray'"
    >
      <div
          @click="collapse.brands = !collapse.brands"
          class="media py-2"
          :aria-expanded="collapse.brands ? 'true' : 'false'"
          aria-controls="collapse-brands"
      >
        <div class="mr-1 text-white bg-primary rounded" style="padding: 8px">
          <feather-icon size="20" icon="ListIcon" />
        </div>

        <div
            class="d-flex justify-content-between w-100"
            style="padding-top: 10px"
        >
          <h5>
            {{ $t("brands_menu") }}
          </h5>

          <div>
            <feather-icon
                size="20"
                :icon="collapse.brands ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            />
          </div>
        </div>
      </div>

      <b-collapse id="collapse-brands" v-model="collapse.brands">
        <b-list-group>
          <b-list-group-item>
            <div class="px-2 row">
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("labels.backgroundcolor") }}</h5>
                <b-form-input
                    type="color"
                    @change="onColorChange($event, 'brands_menu.background_color')"
                    :value="data.brands_menu.background_color"
                    trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("primary_text_color") }}</h5>
                <b-form-input
                    type="color"
                    v-model="data.brands_menu.primary_text_color"
                    @input="updateParent"
                    trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("secondary_text_color") }}</h5>
                <b-form-input
                    type="color"
                    v-model="data.brands_menu.secondary_text_color"
                    @input="updateParent"
                    trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </div>
    
    <!-- === FAV MENU === -->
    <div
      v-if="data.fav_menu"
      :class="collapse.favMenu ? '' : 'border-bottom border-gray'"
    >
      <div
        @click="collapse.favMenu = !collapse.favMenu"
        class="media py-2"
        :aria-expanded="collapse.favMenu ? 'true' : 'false'"
        aria-controls="collapse-tags"
      >
        <div class="mr-1 text-white bg-primary rounded" style="padding: 8px">
          <feather-icon size="20" v-model="data" icon="ListIcon" />
        </div>

        <div
          class="d-flex justify-content-between w-100"
          style="padding-top: 10px"
        >
          <h5>
            {{ $t("fav_menu") }}
          </h5>

          <div>
            <feather-icon
              size="20"
              :icon="collapse.favMenu ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            />
          </div>
        </div>
      </div>

      <b-collapse id="collapse-favMenu" v-model="collapse.favMenu">
        <b-list-group>
          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("labels.background-color") }}</h5>
                <b-form-input
                  type="color"
                  @change="onColorChange($event, 'fav_menu.background_color')"
                  :value="data.fav_menu.background_color"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("primary_text_color") }}</h5>
                <b-form-input
                  type="color"
                  v-model="data.fav_menu.primary_text_color"
                  @input="updateParent"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("secondary_text_color") }}</h5>
                <b-form-input
                  type="color"
                  v-model="data.fav_menu.secondary_text_color"
                  @input="updateParent"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </div>

    <!-- === FEATURED SECTION === -->
    <div
      v-if="data.featured_section"
      :class="collapse.featuredSection ? '' : 'border-bottom border-gray'"
    >
      <div
        @click="collapse.featuredSection = !collapse.featuredSection"
        class="media py-2"
        :aria-expanded="collapse.featuredSection ? 'true' : 'false'"
        aria-controls="collapse-tags"
      >
        <div class="mr-1 text-white bg-primary rounded" style="padding: 8px">
          <feather-icon size="20" icon="ListIcon" />
        </div>

        <div
          class="d-flex justify-content-between w-100"
          style="padding-top: 10px"
        >
          <h5>
            {{ $t("featured_seaction") }}
          </h5>

          <div>
            <feather-icon
              size="20"
              :icon="
                collapse.featuredSection ? 'ChevronUpIcon' : 'ChevronDownIcon'
              "
            />
          </div>
        </div>
      </div>

      <b-collapse id="collapse-featured" v-model="collapse.featuredSection">
        <b-list-group>
          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("shadow_style") }}</h5>
                <b-form-input
                  type="text"
                  v-model="data.featured_section.shadow_style"
                  @input="updateParent"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("primary_text_color") }}</h5>
                <b-form-input
                  type="color"
                  v-model="data.featured_section.primary_text_color"
                  @input="updateParent"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </div>

    <!-- === HEADER SECTION === -->
    <div
      v-if="data.header_section"
      :class="collapse.headerSection ? '' : 'border-bottom border-gray'"
    >
      <div
        @click="collapse.headerSection = !collapse.headerSection"
        class="media py-2"
        :aria-expanded="collapse.headerSection ? 'true' : 'false'"
        aria-controls="collapse-tags"
      >
        <div class="mr-1 text-white bg-primary rounded" style="padding: 8px">
          <feather-icon size="20" icon="ListIcon" />
        </div>

        <div
          class="d-flex justify-content-between w-100"
          style="padding-top: 10px"
        >
          <h5>
            {{ $t("header_section") }}
          </h5>

          <div>
            <feather-icon
              size="20"
              :icon="
                collapse.headerSection ? 'ChevronUpIcon' : 'ChevronDownIcon'
              "
            />
          </div>
        </div>
      </div>

      <b-collapse id="collapse-header" v-model="collapse.headerSection">
        <b-list-group>
          <b-list-group-item>
            <div  class="px-2 d-flex flex-column flex-wrap">
              <!-- Field: lobby Name -->
              <div  class="w-100" v-for="(img,index) in data.header_section.primary_img">

                <b-button class="close-btn" @click="deleteImageHeaderSectionPrimary(index)" variant="outline-primary"
                          size="sm">
                  X
                </b-button>
                <img
                    alt=""
                    style="
                  object-fit: contain;
                  height: 200px;
                  width: 100%;
                  margin: 10px;
                "
                    :src="img.src"
                />
              </div>





              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("labels.image") }}</h5>
                <image-field-form
                    :single-button="true"
                    :text="$t('labels.image')"
                    class="w-100"
                    @previewImage="setPreviewImage"
                    :value="data.header_section.primary_img"
                    @error="errorInLoadImage"
                    @uploadedImage="
                    onUploadedImage($event, 'header_section.primary_img')
                  "
                    path="lobby"
                    :images="images"
                >
                </image-field-form>
              </b-form-group>

            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 d-flex flex-column flex-wrap">
              <!-- Field: lobby Name -->
              <div class="w-100">
                <b-button class="close-btn" @click="onClearImage('header_section.secondary_img')"
                          variant="outline-primary"
                          size="sm">
                  X
                </b-button>
              </div>
              <img
                alt=""
                style="
                  object-fit: contain;
                  height: 200px;
                  width: 100%;
                  margin: 10px;
                "
                :src="data.header_section.secondary_img"
              />
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("secondary_image") }}</h5>
                <image-field-form
                  :single-button="true"
                  :text="$t('labels.image')"
                  class="w-100"
                  @previewImage="setPreviewImage"
                  :value="data.header_section.secondary_img"
                  @changeImage="
                    onChangeImage($event, 'header_section.secondary_img')
                  "
                  @error="errorInLoadImage"
                  @uploadedImage="
                    onUploadedImage($event, 'header_section.secondary_img')
                  "
                  path="lobby"
                  :images="images"
                >
                </image-field-form>
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 d-flex flex-column flex-wrap">
              <!-- Field: lobby Name -->
              <div class="w-100" v-if="data.header_section.third_img">
                <b-button class="close-btn" @click="onClearImage('header_section.third_img')" variant="outline-primary"
                          size="sm">
                  X
                </b-button>
              </div>

              <img
                  v-if="data.header_section.third_img"
                alt=""
                style="
                  object-fit: contain;
                  height: 200px;
                  width: 100%;
                  margin: 10px;
                "
                :src="data.header_section.third_img"
              />
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("third_image") }}</h5>
                <image-field-form
                  :single-button="true"
                  :text="$t('labels.image')"
                  class="w-100"
                  @previewImage="setPreviewImage"
                  v-model="data.header_section.third_img"
                  @changeImage="
                    onChangeImage($event, 'header_section.third_img')
                  "
                  @error="errorInLoadImage"
                  @uploadedImage="
                    onUploadedImage($event, 'header_section.third_img')
                  "
                  path="lobby"
                  :images="images"
                >
                </image-field-form>
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("button_class") }}</h5>
                <b-form-input
                  type="text"
                  v-model="data.header_section.button_class"
                  @input="updateParent"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("button_style") }}</h5>
                <b-form-input
                  type="text"
                  v-model="data.header_section.button_style"
                  @input="updateParent"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2">
              <h5>{{ $t("header_title") }}</h5>
            </div>

            <div class="px-2 row">
              <!-- Field: lobby Name -->

              <b-form-group
                :label="$t('text')"
                class="w-100 col-lg-6"
                label-for="header-text"
              >
                <b-form-input
                  id="header-text"
                  v-model="data.header_section.header_title.text"
                  @input="updateParent"
                  trim
                >
                </b-form-input>
              </b-form-group>
            </div>

            <div class="px-2 row">
              <!-- Field: lobby Name -->

              <b-form-group
                :label="$t('font_size')"
                class="w-100 col-lg-6"
                label-for="header-text"
              >
                <b-form-input
                  id="header-text"
                  v-model="data.header_section.header_title.font_size"
                  @input="updateParent"
                  type="text"
                  trim
                >
                </b-form-input>
              </b-form-group>
            </div>

            <div class="px-2 row">
              <!-- Field: lobby Name -->

              <b-form-group
                :label="$t('primary_text_color')"
                class="w-100 col-lg-6"
              >
                <b-form-input
                  v-model="data.header_section.header_title.primary_text_color"
                  @input="updateParent"
                  type="color"
                  trim
                />
              </b-form-group>
            </div>

            <div class="px-2 row">
              <!-- Field: lobby Name -->

              <b-form-group
                :label="$t('secondary_text_color')"
                class="w-100 col-lg-6"
              >
                <b-form-input
                  type="color"
                  v-model="
                    data.header_section.header_title.secondary_text_color
                  "
                  @input="updateParent"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </div>

    <!-- === TOOLBAR === -->
    <div
      v-if="data.toolbar_menu"
      :class="collapse.toolbar ? '' : 'border-bottom border-gray'"
    >
      <div
        @click="collapse.toolbar = !collapse.toolbar"
        class="media py-2"
        :aria-expanded="collapse.toolbar ? 'true' : 'false'"
        aria-controls="collapse-tags"
      >
        <div class="mr-1 text-white bg-primary rounded" style="padding: 8px">
          <feather-icon size="20" icon="ListIcon" />
        </div>

        <div
          class="d-flex justify-content-between w-100"
          style="padding-top: 10px"
        >
          <h5>
            {{ $t("toolbar_menu") }}
          </h5>

          <div>
            <feather-icon
              size="20"
              :icon="collapse.toolbar ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            />
          </div>
        </div>
      </div>

      <b-collapse id="collapse-toolbar" v-model="collapse.toolbar">
        <b-list-group>
          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("labels.background-color") }}</h5>
                <b-form-input
                  type="color"
                  @change="
                    onColorChange($event, 'toolbar_menu.background_color')
                  "
                  :value="data.toolbar_menu.background_color"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("primary_text_color") }}</h5>
                <b-form-input
                  type="color"
                  v-model="data.toolbar_menu.primary_text_color"
                  @input="updateParent"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("secondary_text_color") }}</h5>
                <b-form-input
                  type="color"
                  v-model="data.toolbar_menu.secondary_text_color"
                  @input="updateParent"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </div>

    <!-- == GAMES === -->
    <div
      v-if="data.brands_games_preview"
      :class="collapse.games ? '' : 'border-bottom border-gray'"
    >
      <div
        @click="collapse.games = !collapse.games"
        class="media py-2"
        :aria-expanded="collapse.games ? 'true' : 'false'"
        aria-controls="collapse-tags"
      >
        <div class="mr-1 text-white bg-primary rounded" style="padding: 8px">
          <feather-icon size="20" icon="ListIcon" />
        </div>

        <div
          class="d-flex justify-content-between w-100"
          style="padding-top: 10px"
        >
          <h5>
            {{ $t("brand_games_preview") }}
          </h5>

          <div>
            <feather-icon
              size="20"
              :icon="collapse.games ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            />
          </div>
        </div>
      </div>

      <b-collapse id="collapse-toolbar" v-model="collapse.games">
        <b-list-group>
          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("primary_text_color") }}</h5>
                <b-form-input
                  v-model="data.brands_games_preview.primary_text_color"
                  @input="updateParent"
                  type="color"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("secondary_text_color") }}</h5>
                <b-form-input
                  v-model="data.brands_games_preview.secondary_text_color"
                  @input="updateParent"
                  type="color"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("button_class") }}</h5>
                <b-form-input
                  v-model="data.brands_games_preview.button_class"
                  @input="updateParent"
                  type="text"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("button_style") }}</h5>
                <b-form-input
                  type="text"
                  v-model="data.brands_games_preview.button_style"
                  @input="updateParent"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("labels.background-color") }}</h5>
                <b-form-input
                  type="color"
                  v-model="data.brands_games_preview.bg_color"
                  @input="updateParent"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>

          <b-list-group-item>
            <div class="px-2 row">
              <!-- Field: lobby Name -->
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("border-color") }}</h5>
                <b-form-input
                  type="color"
                  v-model="data.brands_games_preview.border_color"
                  @input="updateParent"
                  trim
                />
              </b-form-group>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </div>

    <!-- === FOOTER === -->
    <div
      v-if="data.footer_section"
      :class="collapse.footer ? '' : 'border-bottom border-gray'"
    >
      <div
        @click="collapse.footer = !collapse.footer"
        class="media py-2"
        :aria-expanded="collapse.footer ? 'true' : 'false'"
        aria-controls="collapse-tags"
      >
        <div class="mr-1 text-white bg-primary rounded" style="padding: 8px">
          <feather-icon size="20" icon="ListIcon" />
        </div>

        <div
          class="d-flex justify-content-between w-100"
          style="padding-top: 10px"
        >
          <h5>
            {{ $t("footer_section") }}
          </h5>

          <div>
            <feather-icon
              size="20"
              :icon="collapse.footer ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            />
          </div>
        </div>
      </div>

      <b-collapse id="collapse-footer" v-model="collapse.footer">
        <b-list-group>
          <b-list-group-item>
            <div class="px-2 d-flex flex-column justify-end flex-wrap">
              <!-- Field: lobby Name -->
              <div class="w-100">
              <b-button class="close-btn" @click="onClearImage('footer_section.primary_img')" variant="outline-primary"
                        size="sm">
                X
              </b-button>
              </div>
              <img
                  v-if="data.footer_section.primary_img"
                  alt=""
                  style="
                  object-fit: contain;
                  height: 200px;
                  width: 100%;
                  margin: 10px;
                "
                  :src="data.footer_section.primary_img"
              />
              <b-form-group class="w-100 col-lg-6">
                <h5>{{ $t("primary_image") }}</h5>
                <image-field-form
                    :single-button="true"
                    :text="$t('labels.image')"
                    class="w-100"
                    @previewImage="setPreviewImage"
                    :value="data.footer_section.primary_img"
                    @changeImage="
                    onChangeImage($event, 'footer_section.primary_img')
                  "
                    @error="errorInLoadImage"
                    @uploadedImage="
                    onUploadedImage($event, 'footer_section.primary_img')
                  "
                    path="lobby"
                    :images="images"
                >
                </image-field-form>
              </b-form-group>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </div>

    <!-- === PREVIEW === -->
    <b-col v-if="data">
      <b-card>
        <b-row cols="1" cols-sm="2">
          <b-col sm="4" tag="h5" class="text-left ma-auto">{{
            $t("tooltips.preview")
          }}</b-col>
          <b-col sm="12" md="8" class="text-md-right px-0">
            <b-button
              size="sm"
              class="ml-1 text-capitalize"
              :variant="size === breackpoint ? 'primary' : 'link'"
              v-for="(breackpoint, i) in sizes"
              @click="size = breackpoint"
              :key="i"
            >
              <b-icon
                class="d-none d-sm-inline mr-icon"
                style="margin-left: 0.3em"
                :icon="breackpoint"
              ></b-icon>
              {{ breackpoint }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>

      <b-card class="bg-transparent">
        <lobby-preview :lobby="data" :size="size" />
      </b-card>
    </b-col>
  </div>
</template>

<script>
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { set,get, sortBy, cloneDeep } from "lodash";
import LobbyPreview from "@/views/components/whitelabel-templates/App/config/PageEdit/LobbyManagement/external/lobby/lobby-preview/LobbyPreview.vue";
import {BButton} from "bootstrap-vue";

export default {
  name: "LobbySpecificForm",
  components: {
    BButton,
    LobbyPreview,
    ImageFieldForm,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    propData: {
      type: Object,
      required: true,
    },
    collapse: {
      type: Object,
      required: true,
    },
    images: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  data() {
    return {
      data: { ...this.propData },
      sizes: ["phone", "tablet", "laptop"],
      size: "phone",
    };
  },

  methods: {
    deleteImageHeaderSectionPrimary(index){
      this.data.header_section.primary_img.splice(index, 1);
    },
    updateParent() {
      this.$emit("update:data", this.data);
    },

    loadLobbyImages() {
      this.$emit("loadLobbyImages");
    },

    errorInLoadImage(error) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },

    setPreviewImage(e) {
      //console.log(e);
    },

    onColorChange(event, target) {
      // this.data[target] = event;
      set(this.data, target, event);
      // console.log(target);
      // console.log(this.data[target]);
      // console.log(this.data.tags_menu.background_color);
      this.updateParent();
    },

    async onChangeImage(value, target) {
      if (value === "reload" && this.images?.routes?.length) {
        this.loadLobbyImages();
      } else if (value === "error") console.log("error");
      else {
        set(this.data, target, value);
        this.updateParent();
      }
      //Lodash's _.set() method is used to set the value of a property on an object in a nested manner.
    },

    onUploadedImage(data, target) {
      if (data?.files.length) {
        console.log("Target",target);
        console.log("DDDD",this.data);
        if (target === "header_section.primary_img")
        {
          //console.log("Si se ve",get(this.data,target));
          const result = get(this.data,target);
          if (Array.isArray(result)) {
            this.data.header_section.primary_img = result;
          }else
            this.data.header_section.primary_img = [{src:result}];
          this.data.header_section.primary_img.push({src:data.files[data.files.length - 1]});
        }else
          set(this.data, target, data.files[data.files.length - 1]);
        this.updateParent();
      }
    },

    onClearImage(path) {
      this.updateDynamicValue('', path)
    },

    updateDynamicValue(value, path) {
      const properties = path.split('.');
      const last = properties.pop();
      const target = properties.reduce((acc, prop) => acc[prop], this.data);
      if (target) {
        target[last] = value;
      }
    }
  },

  watch: {
    propData: {
      handler(newVal) {
        this.data = { ...newVal };
        console.log("New Data",this.data)
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.close-btn {
  width: 40px;
  float: right;
}
</style>
